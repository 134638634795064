import Papa from "papaparse";

const risksData = [{
  "name": "Delay in Material",
  "id": 123,
  "mentions": 30,
  "project": "Montparnasse",
  "source": "Unifier",
  "tags": ["Planning", "Weather", "Engineering"],

  "documents": [{
    "id": 1,
    "subject": "Project Planning",
    "date": "12th May, 2022",
    "from": "supplier@company.com",
    "to": "client@example.com",
    "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices tellus commodo, mattis tellus id, bibendum libero. Praesent congue et nulla pellentesque imperdiet. Vivamus in magna nec quam congue ultricies. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Because of the delay in delivery of XXX, we expect the project to be delayed by at least 4 weeks. Cras eget nibh non nisl pretium facilisis sit amet in orci. Ut at tempus purus. Fusce dapibus eu lacus ac maximus. Aliquam consequat orci consequat consequat viverra. Quisque aliquam vehicula erat vitae porttitor. Integer pretium, felis sed tristique faucibus, odio ex mollis ex, id vehicula nunc libero ut velit. Aenean tincidunt aliquet ultricies. Nulla porttitor in sem venenatis euismod. Pellentesque et diam tortor.",
    "sentence": "we expect the project to be delayed",
  },
  {
    "id": 2,
    "subject": "Project Planning ",
    "date": "12th May, 2022",
    "from": "supplier@company.com",
    "to": "client@example.com",
    "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultrices tellus commodo, mattis tellus id, bibendum libero. Praesent congue et nulla pellentesque imperdiet. Vivamus in magna nec quam congue ultricies. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Because of the delay in delivery of XXX, we expect the project to be delayed by at least 4 weeks. Cras eget nibh non nisl pretium facilisis sit amet in orci. Ut at tempus purus. Fusce dapibus eu lacus ac maximus. Aliquam consequat orci consequat consequat viverra. Quisque aliquam vehicula erat vitae porttitor. Integer pretium, felis sed tristique faucibus, odio ex mollis ex, id vehicula nunc libero ut velit. Aenean tincidunt aliquet ultricies. Nulla porttitor in sem venenatis euismod. Pellentesque et diam tortor.",
    "sentence": "Lorem",
  }

  ]
},
{
  "name": "Delay in Material",
  "id": 456,
  "mentions": 15,
  "project": "Montparnasse",
  "source": "Lili",
  "tags": ["Flooding"]
}];


let risksMemo = undefined;

export const fetchRisks = () => {
  if (risksMemo) {
    return Promise.resolve(risksMemo);
  }

  return fetch("https://docs.google.com/spreadsheets/d/1RWIld2dafpQ_eksDn2n6Nfb5G8nDnupyk42kCMly-PU/export?format=tsv&gid=0")
    .then(resp => resp.text())
    .then(data => Papa.parse(data, { header: true }))
    .then(v => {
      risksMemo = v.data.map(formatRisk);
      return risksMemo;
    });
}

const formatRisk = risk => {
  //risk.tags = risk.tags.split(',');
  return risk;
}



let emailsMemo = undefined;

export const fetchEmails = () => {
  if (emailsMemo) {
    return Promise.resolve(emailsMemo);
  }

  return fetch("https://docs.google.com/spreadsheets/d/1RWIld2dafpQ_eksDn2n6Nfb5G8nDnupyk42kCMly-PU/export?format=tsv&gid=1047162438")
    .then(resp => resp.text())
    .then(data => Papa.parse(data, { header: true }))
    .then(v => {
      emailsMemo = v.data.map(formatEmail);
      return emailsMemo;
    });
}

const formatEmail = email => {
  email.tags = email.tags.split(',').map(x => x.trim()).filter(x => x)
  return email;
}

export const fetchData = () => {

  return Promise.all([fetchRisks(), fetchEmails()]).then(values => {

    const [risks, emails] = values;
    const editedRisks = risks.map(risk => {
      risk.emails = emails.filter(x => x.risk_id == risk.id);
      //risk.tags = risk.emails.map(x => x.tags).flat();
      risk.all_tags = [...new Set(risk.emails.map(x => x.tags).flat())];
      risk.tag_groups = groupByOccurences(risk.emails.map(x => x.tags).flat());
      risk.all_projects = [...new Set(risk.emails.map(x => x.project).flat())].filter(Boolean);
      risk.project_map = Object.fromEntries([...risk.emails.map(x => [x.project_id, x.project])]);
      return risk;
    }).filter(x => {
      return x.emails.length > 0
    });

    return editedRisks;

  });
}

export const groupByOccurences = array => {

  // returns dict of item:count
  const result = array.reduce((total, value) => {
    total[value] = (total[value] || 0) + 1;
    return total;
  }, {});

  // returns list of (item, count) sorted by desc
  let items = Object.entries(result).sort((a, b) => a[1] - b[1]).reverse();

  return items;

}

export default risksData;