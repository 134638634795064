<template>
  <div>

    <base-header class="pb-2 pt-5 pt-md-6" type="none" style="min-height: 950px">
      <div class="bv-example-row" v-if="loaded">

        <h1 class="mb-5">Lili Monitor detected <span class="text-danger">{{ filteredRisks.length }} active risks</span>
          in your
          documentation.</h1>
        <b-row>
          <b-col md="12">
            <b-card class="mb-2" v-if="isGlobalView">
              <b-card-header class="pr-0" style="border-bottom: 0;">

                <div class="row align-items-center">
                  <div class="col-8">
                    <h2 class="mb-0">Heatmap</h2>
                  </div>
                  <div class="text-right col">

                    <b-dropdown id="dropdown-1" text="This week" class="m-md-2 pull-right" :menuOnRight="true" right>
                      <b-dropdown-item @click="">This week</b-dropdown-item>
                      <b-dropdown-item @click="">This month</b-dropdown-item>
                      <b-dropdown-item @click="">This quarter</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>



              </b-card-header>
              <b-card-body class="pl-0 pr-0" v-if="filteredRisks">
                <heatmap></heatmap>

              </b-card-body>
            </b-card>
          </b-col>

          <b-col md="8">
            <b-card class="risk-list mb-2">
              <b-card-header class="pr-0">

                <div class="row align-items-center">
                  <div class="col">
                    <h2 class="mb-0">Risks Detected</h2>
                  </div>
                  <div class="text-right col">
                    <b-dropdown id="dropdown-1" text="By occurences" class="m-md-2 pull-right" :menuOnRight="true"
                      right>
                      <b-dropdown-item @click="">Older first</b-dropdown-item>
                      <b-dropdown-item @click="">Newer first</b-dropdown-item>
                    </b-dropdown>
                  </div>

                </div>

              </b-card-header>
              <b-card-body class="pl-0 pr-0" v-if="filteredRisks">
                <risk-card v-for="risk in filteredRisks" :name="risk.name" :id="risk.id" :key="risk.id"
                  :source="risk.source" :project="project || risk.project" :tag_groups="risk.tag_groups"
                  :severity="risk.severity" :mentions="risk.emails.length" :isImportant="risk.is_important"
                  :all_projects="risk.all_projects">
                </risk-card>

              </b-card-body>
            </b-card>

          </b-col>
          <b-col md="4">
            <b-card class="mb-2" v-if="isGlobalView">
              <b-card-header>
                <h2 class="mb-0">Projects</h2>
              </b-card-header>
              <b-card-body class="pl-0 pr-0 pb-0" v-if="filteredRisks">

                <div class="filter projects-filter">

                  <div class="option" v-for="x in projects" @click="filterProject(x[0])">
                    <a href="#" :class="{ active: x[0] == project }">{{ x[0] }}

                      <b-badge pill variant="light">{{ x[1] }}</b-badge>

                    </a>
                  </div>
                </div>
              </b-card-body>
            </b-card>
            <b-card class="mb-2">
              <b-card-header>
                <h2 class="mb-0">Filters</h2>
              </b-card-header>
              <b-card-body class="pl-0 pr-0" v-if="filteredRisks">

                <div class="filter">
                  <b>Source</b>
                  <div class="option" v-for="x in filteredSources" @click="filterSource(x[0])">
                    <a href="#" :class="{ active: x[0] == source }">

                      <span v-if="x[0] === 'Unifier'">From Unifier</span>
                      <span v-else>Detected by Lili</span>


                      <b-badge pill variant="light">{{ x[1] }}</b-badge>
                    </a>
                  </div>
                </div>

                <div class="filter">
                  <b>Tags detected by Lili</b>
                  <div class="tags-list">
                    <div class="option" v-for="x in filteredTags" @click="filterTag(x[0])">
                      <a href="#" :class="{ active: x[0] == tag }">
                        {{ x[0] }}
                        <b-badge pill variant="light">{{ x[1] }}</b-badge>
                      </a>
                    </div>
                  </div>
                </div>
              </b-card-body>
            </b-card>

          </b-col>

        </b-row>
      </div>

    </base-header>

  </div>
</template>
<script>
// Charts

import RiskCard from "@/components/RiskCard";
import { fetchData, groupByOccurences } from "@/util/risksData";
import Heatmap from "@/components/Heatmap";

export default {
  components: {
    RiskCard,
    Heatmap
  },
  data() {
    return {

      risks: [],
      source: undefined,
      tag: undefined,
      project: undefined,
      projects: [],
      sources: [],
      tags: [],
      loaded: false,
    };
  },
  methods: {
    filterProject(project) {
      if (this.project === project) {
        this.project = undefined;
      } else {
        this.project = project;
      }

    },
    filterSource(x) {
      if (this.source === x) {
        this.source = undefined;
      } else {
        this.source = x;
      }
    },
    filterTag(x) {
      if (this.tag === x) {
        this.tag = undefined;
      } else {
        this.tag = x;
      }
    }

  },
  computed: {
    filteredRisks: function () {
      let filtered = this.risks;

      let project = this.project || this.$route.query.project;
      if (this.project) {
        filtered = filtered.filter(x => x.all_projects.includes(project));
      }
      if (this.source) {
        filtered = filtered.filter(x => x.source === this.source);
      }


      if (this.tag) {
        filtered = filtered.filter(x => x.all_tags.includes(this.tag));
      }

      return filtered;
    },

    filteredTags: function () {
      return groupByOccurences(this.filteredRisks.map(x => x.all_tags).flat());
    },
    filteredSources: function () {
      return groupByOccurences(this.filteredRisks.map(x => x.source));

    },

    isGlobalView: function () {
      return !Boolean(this.$route.query.project);
    }
  },
  created() {
    fetchData().then(risks => {
      risks = risks.sort((a, b) => a.emails.length - b.emails.length).reverse();

      risks[0].is_important = true;
      this.risks = risks;

      this.projects = groupByOccurences(this.risks.map(x => x.all_projects).flat());
      this.loaded = true;
    });
  },
  mounted() {
  }
};
</script>
<style>
.filter:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 20px;

}

.filter:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.filter b {
  margin-bottom: 10px;
  display: block;
}

.filter .option {
  margin-left: 15px;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter a.active {
  font-weight: bold;
}

.projects-filter {
  font-size: 1.2rem;
}

.projects-filter .option {
  padding: 0.2rem 0;
}

.filter .option .badge-light {
  background-color: rgba(244, 245, 246, 1);
  padding: 0.3rem 0.45rem;
  margin-left: 3px;
  position: relative;
  top: -2px;
}

.pull-right {
  float: right
}

.bg-gradient-yellow {
  background: linear-gradient(87deg, #ffd600 0, #ffe663 100%) !important;
}

.tags-list {
  max-height: 400px;
  overflow-y: scroll;
}

.risk-list {
  height: 100%;
}
</style>
