<template>

  <b-card :class="['risk-card document-card mb-2', { 'active': selected }]" style="overflow: hidden;"
    @click="clickCallback">

    <h4 class="card-title">{{ subject }} <small>{{ date }}</small></h4>
    <div class="content">

      <div class="row left-content">
        <div>{{ sentence }}</div>
        <div class="tags">
          <b-badge v-for="tag in tags" variant="light">{{ tag }}</b-badge>
        </div>
      </div>
    </div>
  </b-card>
  </router-link>
</template>
<script>
import { rootCertificates } from 'tls';

export default {
  name: "document-card",
  props: {
    subject: {
      type: String
    },
    date: {
      type: String
    },
    sentence: {
      type: String
    },

    id: {
      type: String
    },
    selected: {
      type: Boolean
    },

    tags: {
      type: String
    },

  },
  data: function () {
    return {

    }
  },
  methods: {
    clickCallback(index) {
      //this.$router.push("/risk/" + this.id)

      this.$emit('click', this.id);
    }
  },
};
</script>
<style>
.risk-card {
  cursor: pointer;
  overflow: initial !important;
  ;
}

.risk-card .card-title {
  font-size: 1.2rem;
}

.risk-card .card-body .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: initial;
  padding: 10px 20px;
}

.risk-card.document-card .left-content {
  flex-direction: column;
  max-width: initial;
}

.risk-card .tags {
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
  display: inline-flex;
}

.risk-card .right-content {
  align-items: center;

  flex-wrap: wrap;
  gap: 30px;
  display: inline-flex;
}
</style>
