<template>
  <div>
    <div class="container heatmap" style="margin-top: 20px;">
      <div class="row">


        <div class="col-sm-3">
          <div>Projects</div>
          <div v-for="proj in all_projects" class="heatmap-item" @click="projectClickCallback(proj)">
            <small>{{proj}}</small></div>
        </div>

        <div class="col-sm col-risk" v-for="risk in risks">

          <div class="col-risk-label"><small>

              <span v-if="risk.source === 'Unifier'">Unifier - </span>{{risk.name}}</small></div>

          <div v-for="proj in all_projects" class="heatmap-item">
            <div v-if="risk.all_projects.includes(proj)"
              :class="['risk-icon active', getSeverityClass(risk.emails.filter(x=> x.project == proj).length) ||'bg-blue-1']"
              @click="clickCallback(risk.id, proj)">
            </div>
            <div v-else class="risk-icon risk-empty"></div>
          </div>
        </div>

        <div class="col-sm-3 ml-6 legend">
          <div class="mb-2">Number of occurences</div>

          <div><span class="risk-icon active bg-blue-1" style="display:inline-block"></span> Low</div>
          <div><span class="risk-icon active bg-blue-2" style="display:inline-block"></span> Medium</div>
          <div><span class="risk-icon active bg-blue-3" style="display:inline-block"></span> High</div>

        </div>
      </div>
    </div>

  </div>
</template>
<script>
import { fetchData, groupByOccurences } from "@/util/risksData";

const severityMap = {
  "1": "bg-blue-1",
  "2": "bg-blue-2",
  "3": "bg-blue-3"
}
export default {
  components: {
  },
  data() {
    return {

      document: undefined,
      loaded: false,
      all_projects: [],
      risks: [],
      severityMap,
    };
  },
  methods: {
    clickCallback(riskId, project) {
      this.$router.push("/risk/" + riskId + "?project=" + project)
    },
    projectClickCallback(project) {
      this.$router.push("/risks/?project=" + project)
    },

    getSeverityClass(numEmails) {
      if (numEmails < 3) {
        return "bg-blue-1";
      } else if (numEmails < 10) {
        return "bg-blue-2";
      }

      return "bg-blue-3";

    }
  },
  created() {
    fetchData().then(risks => {
      this.risks = risks;

      this.projects = groupByOccurences(this.risks.map(x => x.all_projects).flat()).sort

      this.all_projects = [...new Set(this.risks.map(x => x.all_projects).flat())].sort();
    });
  },

  mounted() {
  }
};
</script>
<style>
.col-risk {
  width: 50px;
  max-width: 50px;
}

.col-risk-label {
  transform: rotate(-45deg);
  width: 200px;
  position: relative;
  top: -66px;
  left: -22px;
}

.risk-icon {
  width: 20px;
  height: 20px;

  background-color: #e8eaf3;
}

.risk-icon.active {
  cursor: pointer;

}

.risk-icon.active:hover {
  opacity: 0.7;
}

.risk-icon.risk-empty {
  background-color: #e8eaf3;
}

.heatmap-item {
  height: 25px;
}

.legend {
  color: #879cb0;
}
</style>
  