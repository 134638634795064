<template>

  <b-card class="risk-card mb-2" style="overflow: hidden;" @click="clickCallback">

    <h4 class="card-title">Risk: {{ name }}


      <b-badge variant="secondary"><small v-if="source == 'Unifier'">from {{ source }}</small>
        <small v-else>detected by {{ source }}</small>
      </b-badge>
      <span style="float:right"><i :class="['fas fa-star icon-button', { 'text-yellow': isImportant }]"></i></span>
    </h4>
    <div class="content">

      <div class="row left-content">
        <small v-if="project">Project: {{project}}</small>
        <small v-else>Projects:
          <span v-for="proj, index in all_projects" variant="light">{{ proj }}<span
              v-if="index != Object.keys(all_projects).length - 1">, </span>
          </span>
        </small>

        <div class="tags">
          <b-badge v-for="tag in tag_groups.slice(0, 5)" variant="light">{{ tag[0] }}
            <!-- {{tag[1]}} -->
          </b-badge>
        </div>
      </div>

      <div class="row right-content">
        <div>{{ mentions }} mentions</div>

        <b-dropdown id="dropdown-1" text="actions" class="m-md-2" :menuOnRight="true" right>
          <b-dropdown-item @click=""><i class="fas fa-check"></i> Update in Unifier</b-dropdown-item>
          <b-dropdown-item @click=""><i class="fas fa-star"></i> Mark as important</b-dropdown-item>
          <b-dropdown-item @click=""><i class="fas fa-flag"></i> Report as invalid</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div :class="['risk-bg-gradient', gradientClass]" style="position: absolute; left: 0;top:0; bottom:0; width: 10px">
    </div>
  </b-card>
</template>
<script>

const severityMap = {
  "1": "bg-blue-1",
  "2": "bg-blue-2",
  "3": "bg-blue-3"
}

export default {
  name: "risk-card",
  props: {
    name: {
      type: String
    },
    id: {
      type: String,
    },

    source: {
      type: String
    },
    project: {
      type: String
    },
    tag_groups: {
      type: Array
    },
    all_projects: {
      type: Array
    },
    mentions: {
      type: Number
    },

    severity: {
      type: String
    },
    isImportant: {
      type: Boolean
    },

  },
  data: function () {
    return {
      gradientClass: severityMap[this.severity]


    }
  },
  methods: {
    clickCallback(index) {
      this.$router.push("/risk/" + this.id)
    }
  },
};
</script>
<style>
.risk-card {
  cursor: pointer;
  overflow: initial !important;
  transition: all 0.15s ease;
}

.risk-bg-gradient {
  border-radius: 0.375rem 0 0 0.375rem
}

.risk-card.active {
  border-color: #2643e9;
}

.risk-card:hover {
  /* box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px); */

  border-color: rgba(0, 0, 0, 0.2);
}

.risk-card .card-title {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.risk-card .card-body .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: initial;
  padding: 10px 20px;
}

.risk-card .left-content {
  flex-direction: column;
  max-width: 65%;
}

.risk-card .tags {
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
  display: inline-flex;
}

.risk-card .right-content {
  align-items: center;

  flex-wrap: wrap;
  gap: 30px;
  display: inline-flex;
}


.risk-card .card-body {
  padding: 1rem 1rem 0 1rem;
}

.icon-button {
  opacity: 0.8;
  color: #ccc
}

.icon-button:hover {
  opacity: 1;
}

.icon-button.active {
  color: yellow;
}
</style>
