<template>
    <div>
        <base-header class="pb-6 pt-5" type="none" style="min-height: 950px">
            <b-container fluid>
                <div class="text-center pb-6 pt-6">
                    <h1 class="text-center pb-10">Processing your data...</h1>

                    <h2>
                        <animated-integer v-bind:value="fileNum"></animated-integer> documents were synchronized
                    </h2>

                    <p>
                        <animated-integer v-bind:value="emailNum"></animated-integer> emails (incl. attachments),
                        <animated-integer v-bind:value="pdfNum"></animated-integer> pdfs,
                        <animated-integer v-bind:value="docNum"></animated-integer> docs,
                        <animated-integer v-bind:value="pptNum"></animated-integer> ppts,
                        <animated-integer v-bind:value="xlsNum"></animated-integer> xls
                    </p>
                </div>

                <section class="section">

                    <horizontal-stepper ref="stepper" :steps="demoSteps" @completed-step="completeStep"
                        :currentStep="demoSteps[1]" @active-step="isStepActive" @stepper-finished="alert">
                    </horizontal-stepper>

                    <div class="text-right pb-6 pt-6 pr-6">
                        <router-link to="/risks" class="btn btn-primary pull-right">Continue</router-link>
                    </div>
                </section>


            </b-container>
        </base-header>

    </div>
</template>
<script>
import HorizontalStepper from 'vue-stepper';
import AnimatedInteger from '@/components/AnimatedInteger.vue';
export default {
    components: {
        HorizontalStepper,
        AnimatedInteger
    },
    data() {
        return {
            fileNum: 10996,
            emailNum: 7250,
            pdfNum: 2582,
            docNum: 605,
            pptNum: 351,
            xlsNum: 208,
            demoSteps: [
                {
                    icon: '',
                    name: 'first',
                    title: 'Data Centralization',
                    subtitle: "All project documentation, including emails, is synchronized and filtered according to the client's confidentiality rules.",
                    completed: false
                },
                {
                    icon: '',
                    name: 'second',
                    title: 'NLP Analysis',
                    subtitle: 'Documents go through a preprocessing and NLP data pipeline.',
                    completed: false
                },

                {
                    icon: '',
                    name: 'second',
                    title: 'AI Detection',
                    subtitle: 'Our pre-trained algorithms detect mentions of 120 types of disturbances.',
                    completed: false
                },

                {
                    icon: '',
                    name: 'second',
                    title: 'Ready',
                    subtitle: "Lili's analysis is complete, your dashboards are ready for review.",
                    completed: false
                }
            ]
        }
    },
    methods: {
        // Executed when @completed-step event is triggered
        // completeStep(payload) {
        //     this.demoSteps.forEach((step) => {
        //         if (step.name === payload.name) {
        //             step.completed = true;
        //         }
        //     })
        // },
        // // Executed when @active-step event is triggered
        // isStepActive(payload) {
        //     this.demoSteps.forEach((step) => {
        //         if (step.name === payload.name) {
        //             if(step.completed === true) {
        //                 step.completed = false;
        //             }
        //         }
        //     })
        // },
        // Executed when @stepper-finished event is triggered
        alert(payload) {
            alert('end')
        }
    },
    mounted() {
        // setTimeout(() => {
        //     this.demoSteps[0].completed = true;
        // }, 1000);

        setTimeout(() => {
            // this.$refs.stepper.steps[0].completed = true;
            this.$refs.stepper.activateStep(1);
        }, 2000);

        setTimeout(() => {
            // this.$refs.stepper.steps[1].completed = true;
        }, 4200);
        setTimeout(() => {
            this.$refs.stepper.activateStep(2);
        }, 4100);

        setTimeout(() => {
            //this.$refs.stepper.steps[2].completed = true;
            // this.$refs.stepper.steps[1].completed = true;
            // this.$refs.stepper.steps[2].completed = true;
        }, 6100);

        setTimeout(() => {
            // this.$refs.stepper.steps[1].completed = true;
            // this.$refs.stepper.steps[2].completed = true;
            this.$refs.stepper.activateStep(3);
        }, 6000);

    }
}
</script>
<style>
.modal-header {
    align-items: center;
}

.modal-title {
    font-size: 1.2rem;
}

.icon-columns {
    display: flex;
    justify-content: space-between;
}

.icon-columns>div {
    max-width: 400px;
}

.step .circle {
    background-color: initial !important;
    margin-bottom: 0 !important;
}

.step .circle .material-icons {
    height: 56px;
    width: 56px;
}


.stepper-box {
    background-color: initial !important;
    box-shadow: none !important;
}


.stepper-box .content,
.stepper-box .bottom {
    display: none !important;
}

.stepper-box .step-title {
    margin-top: 20px;
    transition: all .3s ease;
}

.stepper-box .step-title h4 {
    font-size: 1.2rem;
}

.stepper-box .step-title h5 {
    font-size: 14px !important;
    font-weight: normal !important;
}


.stepper-box .step-subtitle {
    padding: 8px;
}

.stepper-box .top .steps-wrapper .step .circle i {
    background-color: #2dce89 !important;
}

.stepper-box .top .steps-wrapper .step.deactivated .circle i {
    background-color: grey !important;
}
</style>
