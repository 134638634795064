<template>
  <div>
    <base-header class="pb-6 pb-8 pt-5 pt-md-6" type="none" v-if="loaded">
      <div class="mb-3">
        <router-link to="/risks"><i class="fas fa-chevron-left"></i> Back</router-link>
      </div>
      <risk-card :name="risk.name" :id="risk.id" :source="risk.source" :tag_groups="risk.tag_groups"
        :severity="risk.severity" :mentions="emails && emails.length" :all_projects="risk.all_projects"
        :project="project">
      </risk-card>

      <div class="bv-example-row">
        <b-row>
          <b-col>
            <b-card class="mb-2">
              <b-card-header>
                <h2 class="mb-0">Mentions</h2>
              </b-card-header>
              <b-card-body class="pl-0 pr-0 mentions-body">

                <document-card v-for="doc in this.emails" :id="doc.id" :subject="doc.subject" :sentence="doc.sentence"
                  :date="doc.date" :tags="doc.tags" :selected="doc.id === (document && document.id)"
                  @click="clickedDocument">
                </document-card>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col>
            <b-card class="mb-2" style="height: 100%">
              <b-card-header>
                <h2 class="mb-0">Preview</h2>
              </b-card-header>
              <b-card-body class="pl-0 pr-0" style="min-height: 400px">
                <div v-if="document">

                  <h3>{{ document.subject }} <small>{{ document.date }}</small></h3>

                  <p>
                    from: {{ document.from }}<br />
                    to: {{ document.to }}</p>
                  <hr />

                  <p v-html="highlight()"></p>

                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>


    </base-header>


  </div>
</template>
<script>
// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';

// Components
import BaseProgress from '@/components/BaseProgress';
import StatsCard from '@/components/Cards/StatsCard';

// Tables
import SocialTrafficTable from './Dashboard/SocialTrafficTable';
import PageVisitsTable from './Dashboard/PageVisitsTable';


import RiskCard from "@/components/RiskCard";
import DocumentCard from "@/components/DocumentCard";

import risksData, { fetchRisks, fetchEmails, fetchData } from "@/util/risksData";


export default {
  components: {
    LineChart,
    BarChart,
    BaseProgress,
    StatsCard,
    PageVisitsTable,
    SocialTrafficTable,
    RiskCard,
    DocumentCard
  },
  data() {
    return {

      document: undefined,
      loaded: false,
      risk: {},
      emails: []
    };
  },
  methods: {
    clickedDocument(documentId) {
      this.document = documentId ? this.risk.emails.filter(x => x.id === documentId)[0] : undefined;
    },
    highlight() {
      if (!this.document) {
        return undefined;
      }
      return this.document.content.replace(new RegExp(this.document.sentence, "gi"), match => {
        return '<span class="highlightText">' + match + '</span>';
      });
    },

    update() {
      this.risk = this.risks.filter(x => x.id === this.$route.params.id)[0];


      this.project = this.$route.query.project;

      this.emails = this.project ? this.risk.emails.filter(x => x.project === this.project) : this.risk.emails;

      this.document = this.emails[0];
    }
  },
  created() {
    fetchData().then(risks => {
      this.risks = risks;

      this.update();
      this.loaded = true;
    });
  },

  mounted() {
  }
};
</script>
<style>
.highlightText {
  background: yellow;
}

.back-button {
  margin-bottom:
}

.mentions-body {
  max-height: 65vh;
  overflow-y: scroll;
}
</style>
