<template>
  <div>


    <div class="pb-8 pt-5 pt-lg-8 d-flex align-items-center" style="min-height: 600px">
      <b-container fluid>
        <!-- Header container -->
        <b-container fluid class="d-flex align-items-center">
          <b-row>
            <b-col md="6">
              <h1 class="display-2">Lili.Monitor Demo</h1>
              <p class="mt-0 mb-5 description">This experience demonstrates how NLP can be used against project
                documentation and emails to create alerts on disruptions such as delay, litigation, risks, etc.</p>


              <b-button v-b-modal.modal-1 variant="primary" size="lg" class="btn-xl">Get Started</b-button>

              <b-modal id="modal-1" title="Project data Synchronization">

                <p class="my-4">
                  For the purpose of this demo, we will sync a sample project.

                <p>
                  ✓ Sync <b>Office 365 Server</b><br />
                  ✓ Sync <b>Unifier Documentation</b><br />
                  ✓ Sync <b>Unifier Risks</b>
                </p>
                </p>

                <template #modal-footer>
                  <router-link to="/loading" class="btn btn-primary">Sync Unifier Data</router-link>
                </template>

              </b-modal>
            </b-col>

            <b-col md="6">
              <b-img fluid class="img-responsive" rounded src="/img/lili-diagram.png" />
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </div>
    <base-header class="pb-6 pt-5" type="none">
      <b-container fluid>
        <h2 class="mb-2 pb-5">About Lili.ai</h2>
        <b-row class="icon-columns">
          <b-col md="4">
            <svg width="81" height="75" viewBox="0 0 81 75" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M37 11C37 15.4183 33.4183 19 29 19C24.5817 19 21 15.4183 21 11C21 6.58172 24.5817 3 29 3C33.4183 3 37 6.58172 37 11ZM19 36C19 40.4183 15.4183 44 11 44C6.58172 44 3 40.4183 3 36C3 31.5817 6.58172 28 11 28C15.4183 28 19 31.5817 19 36ZM37 64C37 68.4183 33.4183 72 29 72C24.5817 72 21 68.4183 21 64C21 59.5817 24.5817 56 29 56C33.4183 56 37 59.5817 37 64Z"
                stroke="black" stroke-width="6" />
              <path
                d="M76 36.5L78.1213 38.6213L80.295 36.4476L78.069 34.3276L76 36.5ZM67.569 24.3276C66.3692 23.1849 64.4702 23.2312 63.3276 24.431C62.1849 25.6308 62.2312 27.5298 63.431 28.6724L67.569 24.3276ZM63.3787 44.8787C62.2071 46.0503 62.2071 47.9497 63.3787 49.1213C64.5503 50.2929 66.4497 50.2929 67.6213 49.1213L63.3787 44.8787ZM78.069 34.3276L67.569 24.3276L63.431 28.6724L73.931 38.6724L78.069 34.3276ZM73.8787 34.3787L63.3787 44.8787L67.6213 49.1213L78.1213 38.6213L73.8787 34.3787ZM20.5 39.5H46.5V33.5H20.5V39.5ZM46.5 39.5H76V33.5H46.5V39.5ZM47.2276 33.5896C44.5355 32.9165 40.4724 31.297 37.2035 28.7285C33.9494 26.1718 31.8485 22.9966 31.9978 19.1153L26.0022 18.8847C25.7515 25.4034 29.4006 30.2282 33.4965 33.4465C37.5776 36.653 42.4645 38.5835 45.7724 39.4104L47.2276 33.5896ZM45.6005 33.638C42.2911 34.6781 37.4433 37.1761 33.3817 40.5914C29.415 43.927 25.5 48.7349 25.5 54.5H31.5C31.5 51.4651 33.6684 48.1897 37.2433 45.1836C40.7233 42.2573 44.8756 40.1552 47.3995 39.362L45.6005 33.638Z"
                fill="black" />
            </svg>

            <h3>Simple set-up</h3>
            <p>we take your data as it exists and provide first results within days</p>
          </b-col>

          <b-col md="4">
            <svg width="76" height="82" viewBox="0 0 76 82" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0 43.2065H10.1333L12.8615 34.3806L18.3179 51.0516L26.1128 22.6129L30.0103 62.8194L38.1949 3L45.9897 79L53.7846 14.2774L62.7487 53.9935L67.8154 42.2258H76"
                stroke="black" stroke-width="6" stroke-linejoin="round" />
            </svg>


            <h3>Detect Weak Signals</h3>
            <p>Up to 6 months before the official report, giving you that much time to find solutions</p>
          </b-col>

          <b-col md="4">
            <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M52.8125 58.4444H75L68.7653 52.9806C67.928 52.2466 67.2639 51.3754 66.8109 50.4165C66.3579 49.4577 66.1248 48.43 66.125 47.3922V35.1111C66.1257 30.2848 64.4191 25.5769 61.2403 21.6357C58.0616 17.6945 53.5668 14.7137 48.375 13.1039V11.7778C48.375 9.71498 47.44 7.73667 45.7756 6.27806C44.1112 4.81944 41.8538 4 39.5 4C37.1462 4 34.8888 4.81944 33.2244 6.27806C31.56 7.73667 30.625 9.71498 30.625 11.7778V13.1039C20.2856 16.3083 12.875 24.9533 12.875 35.1111V47.3961C12.875 49.4883 11.9254 51.4989 10.2347 52.9806L4 58.4444H26.1875M52.8125 58.4444H26.1875M52.8125 58.4444V62.3333C52.8125 65.4275 51.4099 68.395 48.9134 70.5829C46.4168 72.7708 43.0307 74 39.5 74C35.9693 74 32.5832 72.7708 30.0866 70.5829C27.5901 68.395 26.1875 65.4275 26.1875 62.3333V58.4444"
                stroke="black" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" />
            </svg>



            <h3>Trigger alerts</h3>
            <p>Extract timelines from all written documentation, including emails.</p>
          </b-col>
        </b-row>
      </b-container>
    </base-header>

  </div>
</template>
<script>
// Charts
import * as chartConfigs from '@/components/Charts/config';
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';

// Components
import BaseProgress from '@/components/BaseProgress';
import StatsCard from '@/components/Cards/StatsCard';

// Tables
import SocialTrafficTable from './Dashboard/SocialTrafficTable';
import PageVisitsTable from './Dashboard/PageVisitsTable';

export default {
  components: {
    LineChart,
    BarChart,
    BaseProgress,
    StatsCard,
    PageVisitsTable,
    SocialTrafficTable
  },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  }
};
</script>
<style>
.modal-header {
  align-items: center;
}

.modal-title {
  font-size: 1.2rem;
}

.icon-columns {
  display: flex;
  justify-content: space-between;
}

.icon-columns>div {
  max-width: 400px;
}

.btn-xl {
  font-size: 1.4rem;
}

.description {
  font-size: 1.2rem;
}

p {
  font-weight: normal;
}

* h3 {
  margin-top: 10px;
}
</style>
